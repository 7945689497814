import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App.jsx";
import { Skills } from "./componets/Skills.jsx";

import { Home } from "./page/Home.jsx";
import { Home2 } from "./page/Home2.jsx";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

ReactDOM.createRoot(document.getElementById("root")).render(
  <React.StrictMode>
    <Home />
  </React.StrictMode>
);
