import React from "react";
import "./css/bootstrap-icons.css";
import "./css/bootstrap.min.css";
import "./css/magnific-popup.css";
import "./css/templatemo-first-portfolio-style.css";

// // import "./js/jquery.min.js";
// import "./js/click-scroll.js";
// import "./js/custom.js";
// import "./js/magnific-popup-options.js";
// import "./js/jquery.magnific-popup.min.js";
// import "./js/jquery.sticky.js";
// import "./js/bootstrap.min.js";
// import $ from "jquery";

import { Skills1 } from "../componets/Skills1";
import { Skills } from "../componets/Skills";
import { Box } from "@mui/material";
import mainImg from "../assets/hero.png";
import aboutImg from "../assets/about2.png";

export const Home = () => {
  const handleSubmit = () => {
    console.log("submited");
  };
  return (
    <>
      <section className="preloader">
        <div className="spinner">
          <span className="spinner-rotate"></span>
        </div>
      </section>
      <nav className="navbar navbar-expand-lg">
        <div className="container">
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarNav"
            aria-controls="navbarNav"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>

          <a className="navbar-brand mx-auto mx-lg-0">Code by Amanuel</a>

          <div className="d-flex align-items-center d-lg-none">
            <i className="navbar-icon bi-telephone-plus me-3"></i>
            <a className="custom-btn btn" href="#section_5">
              0963453433
            </a>
          </div>

          <div className="collapse navbar-collapse" id="navbarNav">
            <ul className="navbar-nav ms-lg-5">
              <li className="nav-item">
                <a className="nav-link click-scroll" href="#section_1">
                  Home
                </a>
              </li>

              <li className="nav-item">
                <a className="nav-link click-scroll" href="#section_2">
                  About Me
                </a>
              </li>

              {/* <li className="nav-item">
                <a className="nav-link click-scroll" href="#section_3">
                  Services
                </a>
              </li> */}

              <li className="nav-item">
                <a className="nav-link click-scroll" href="#section_4">
                  Projects
                </a>
              </li>

              <li className="nav-item">
                <a className="nav-link click-scroll" href="#section_5">
                  Contact
                </a>
              </li>
            </ul>

            <div className="d-lg-flex align-items-center d-none ms-auto">
              <i className="navbar-icon bi-telephone-plus me-3"></i>
              <a className="custom-btn btn" href="#section_5">
                0963453433
              </a>
            </div>
          </div>
        </div>
      </nav>

      <main>
        <section
          className="hero d-flex justify-content-center align-items-center"
          id="section_1"
        >
          <div className="container">
            <div className="row">
              <div className="col-lg-7 col-12">
                <div className="hero-text">
                  <div className="hero-title-wrap d-flex align-items-center mb-4">
                    <h1 className="hero-title ms-3 mb-0">Hi there! 👋</h1>
                  </div>

                  <h2 className="mb-4">I’m a full stack web developer.</h2>
                  <p className="mb-4">
                    <a className="custom-btn btn custom-link" href="#section_5">
                      Let's talk
                    </a>
                  </p>
                </div>
              </div>

              <div className="col-lg-5 col-12 position-relative pb-5">
                <div className="hero-image-wrap"></div>
                <img src={mainImg} className="hero-image img-fluid" alt="" />
              </div>
            </div>
          </div>

          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320">
            <path
              fill="#535da1"
              fillOpacity="1"
              d="M0,160L24,160C48,160,96,160,144,138.7C192,117,240,75,288,64C336,53,384,75,432,106.7C480,139,528,181,576,208C624,235,672,245,720,240C768,235,816,213,864,186.7C912,160,960,128,1008,133.3C1056,139,1104,181,1152,202.7C1200,224,1248,224,1296,197.3C1344,171,1392,117,1416,90.7L1440,64L1440,0L1416,0C1392,0,1344,0,1296,0C1248,0,1200,0,1152,0C1104,0,1056,0,1008,0C960,0,912,0,864,0C816,0,768,0,720,0C672,0,624,0,576,0C528,0,480,0,432,0C384,0,336,0,288,0C240,0,192,0,144,0C96,0,48,0,24,0L0,0Z"
            ></path>
          </svg>
        </section>

        <section className="about section-padding" id="section_2">
          <div className="container">
            <div className="row">
              <div className="col-lg-6 col-12">
                <img src={aboutImg} className="about-image img-fluid" alt="" />
              </div>

              <div className="col-lg-6 col-12 mt-5 mt-lg-0">
                <div className="about-thumb">
                  <div className="section-title-wrap d-flex justify-content-end align-items-center mb-4">
                    <h2
                      className="text-white mb-0"
                      style={{
                        display: "flex",
                        marginRight: "auto",
                      }}
                    >
                      ABOUT ME
                    </h2>
                  </div>

                  <p>
                    I'm Amanuel Tesfaye, a passionate web developer specializing
                    in React,React Native, Node.js, MongoDB, and WordPress. I've
                    had the privilege of working on diverse projects, including
                    developing a website for a tour and travel agency, online
                    examination system integrated with AI, Research Managment
                    System for Arbaminch University and I am currently working
                    on a website that makes learning easy with AI.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>
        <Box
          sx={{
            width: "100vw",
          }}
        >
          <Skills1 />
        </Box>
        {/* <section className="clients section-padding">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-lg-12 col-12">
                <h3 className="text-center mb-5">Companies I've had worked</h3>
              </div>

              <div className="col-lg-2 col-4 ms-auto clients-item-height">
                <img
                  src="images/clients/cachet.svg"
                  className="clients-image img-fluid"
                  alt=""
                />
              </div>

              <div className="col-lg-2 col-4 clients-item-height">
                <img
                  src="images/clients/guitar-center.svg"
                  className="clients-image img-fluid"
                  alt=""
                />
              </div>

              <div className="col-lg-2 col-4 clients-item-height">
                <img
                  src="images/clients/tokico.svg"
                  className="clients-image img-fluid"
                  alt=""
                />
              </div>

              <div className="col-lg-2 col-4 clients-item-height">
                <img
                  src="images/clients/shopify.svg"
                  className="clients-image img-fluid"
                  alt=""
                />
              </div>

              <div className="col-lg-2 col-4 me-auto clients-item-height">
                <img
                  src="images/clients/profil-rejser.svg"
                  className="clients-image img-fluid"
                  alt=""
                />
              </div>
            </div>
          </div>
        </section> */}

        {/* <section className="services section-padding" id="section_3">
          <div className="container">
            <div className="row">
              <div className="col-lg-10 col-12 mx-auto">
                <div className="section-title-wrap d-flex justify-content-center align-items-center mb-5">
                  <h2 className="text-white ms-4 mb-0">Services</h2>
                </div>

                <div className="row pt-lg-5">
                  <div className="col-lg-6 col-12">
                    <div className="services-thumb">
                      <div className="d-flex flex-wrap align-items-center border-bottom mb-4 pb-3">
                        <h3 className="mb-0">Websites</h3>

                        <div className="services-price-wrap ms-auto">
                          <p className="services-price-text mb-0">$2,400</p>
                          <div className="services-price-overlay"></div>
                        </div>
                      </div>

                      <p>
                        You may want to explore Too CSS for great collection of
                        free HTML CSS templates.
                      </p>

                      <a
                        href="#"
                        className="custom-btn custom-border-btn btn mt-3"
                      >
                        Discover More
                      </a>

                      <div className="services-icon-wrap d-flex justify-content-center align-items-center">
                        <i className="services-icon bi-globe"></i>
                      </div>
                    </div>
                  </div>

                  <div className="col-lg-6 col-12">
                    <div className="services-thumb services-thumb-up">
                      <div className="d-flex flex-wrap align-items-center border-bottom mb-4 pb-3">
                        <h3 className="mb-0">Branding</h3>

                        <div className="services-price-wrap ms-auto">
                          <p className="services-price-text mb-0">$1,200</p>
                          <div className="services-price-overlay"></div>
                        </div>
                      </div>

                      <p>
                        You can explore more CSS templates on TemplateMo website
                        by browsing through different tags.
                      </p>

                      <a
                        href="#"
                        className="custom-btn custom-border-btn btn mt-3"
                      >
                        Discover More
                      </a>

                      <div className="services-icon-wrap d-flex justify-content-center align-items-center">
                        <i className="services-icon bi-lightbulb"></i>
                      </div>
                    </div>
                  </div>

                  <div className="col-lg-6 col-12">
                    <div className="services-thumb">
                      <div className="d-flex flex-wrap align-items-center border-bottom mb-4 pb-3">
                        <h3 className="mb-0">Ecommerce</h3>

                        <div className="services-price-wrap ms-auto">
                          <p className="services-price-text mb-0">$3,600</p>
                          <div className="services-price-overlay"></div>
                        </div>
                      </div>

                      <p>
                        If you need a customized ecommerce website for your
                        business, feel free to discuss with me.
                      </p>

                      <a
                        href="#"
                        className="custom-btn custom-border-btn btn mt-3"
                      >
                        Discover More
                      </a>

                      <div className="services-icon-wrap d-flex justify-content-center align-items-center">
                        <i className="services-icon bi-phone"></i>
                      </div>
                    </div>
                  </div>

                  <div className="col-lg-6 col-12">
                    <div className="services-thumb services-thumb-up">
                      <div className="d-flex flex-wrap align-items-center border-bottom mb-4 pb-3">
                        <h3 className="mb-0">SEO</h3>

                        <div className="services-price-wrap ms-auto">
                          <p className="services-price-text mb-0">$1,450</p>
                          <div className="services-price-overlay"></div>
                        </div>
                      </div>

                      <p>
                        To list your website first on any search engine, we will
                        work together. First Portfolio is one-page CSS Template
                        for free download.
                      </p>

                      <a
                        href="#"
                        className="custom-btn custom-border-btn btn mt-3"
                      >
                        Discover More
                      </a>

                      <div className="services-icon-wrap d-flex justify-content-center align-items-center">
                        <i className="services-icon bi-google"></i>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section> */}

        <section className="projects section-padding" id="section_4">
          <div className="container">
            <div className="row">
              <div className="col-lg-8 col-md-8 col-12 ms-auto">
                <div className="section-title-wrap d-flex justify-content-center align-items-center mb-4 p-4">
                  <h2 className="text-white ms-4 mb-0">MY PREVIOUS WORKS</h2>
                </div>
              </div>

              <div className="clearfix"></div>

              <div className="col-lg-4 col-md-6 col-12">
                <div className="projects-thumb">
                  <div className="projects-info">
                    <small className="projects-tag">Website</small>

                    <h3 className="projects-title">
                      Lion of Juda Tour and Travel Agency
                    </h3>
                  </div>

                  <a
                    href="https://lionofjudatourandtravel.com"
                    className="popup-image"
                  >
                    <img
                      src="https://portfolio.lionofjudatourandtravel.com/images/576%2C1489x841%2B229%2B0/8405510/Screenshot2024-04-18162734.jpg"
                      className="projects-image img-fluid"
                      alt=""
                    />
                  </a>
                </div>
              </div>

              <div className="col-lg-4 col-md-6 col-12">
                <div className="projects-thumb">
                  <div className="projects-info">
                    <small className="projects-tag">Website</small>

                    <h3 className="projects-title">
                      Smart Path: online learning platform with AI
                    </h3>
                  </div>
                  {/* fsdfsld;k */}
                  <a
                    href="https://smart-path-two.vercel.app/"
                    className="popup-image"
                  >
                    <img
                      src="https://portfolio.lionofjudatourandtravel.com/images/576/9361276/Screenshot2024-06-20133724.jpg"
                      className="projects-image img-fluid"
                      alt=""
                    />
                  </a>
                </div>
              </div>

              <div className="col-lg-4 col-md-6 col-12">
                <div className="projects-thumb">
                  <div className="projects-info">
                    <small className="projects-tag">Website</small>

                    <h3 className="projects-title">
                      Research Managment System For Arbaminch University
                    </h3>
                  </div>

                  <a
                    href="https://research-managment-system.vercel.app/"
                    className="popup-image"
                  >
                    <img
                      src="https://portfolio.lionofjudatourandtravel.com/images/576/9361280/Screenshot2024-06-20133745.jpg"
                      className="projects-image img-fluid"
                      alt=""
                    />
                  </a>
                </div>
              </div>
              <div className="col-lg-4 col-md-6 col-12">
                <div className="projects-thumb">
                  <div className="projects-info">
                    <small className="projects-tag">Website</small>

                    <h3 className="projects-title">Helper AI</h3>
                  </div>

                  <a
                    href="https://github.com/amanuelt312/Helper-AI"
                    className="popup-image"
                  >
                    <img
                      src="https://portfolio.lionofjudatourandtravel.com/images/576/8408454/hsdabtbn91slpb2x2p2amjidzkve.png"
                      className="projects-image img-fluid"
                      alt=""
                    />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="contact section-padding" id="section_5">
          <div className="container">
            <div className="row">
              <div className="col-lg-6 col-md-8 col-12">
                <div className="section-title-wrap d-flex justify-content-center align-items-center mb-5 p-3">
                  <h2 className="text-white ms-4 mb-0">Say Hi</h2>
                </div>
              </div>

              <div className="clearfix"></div>

              <div className="col-lg-3 col-md-6 col-12 pe-lg-0">
                <div className="contact-info contact-info-border-start d-flex flex-column">
                  <strong className="site-footer-title d-block mb-3">
                    Services
                  </strong>

                  <ul className="footer-menu">
                    <li className="footer-menu-item">
                      <a href="#" className="footer-menu-link">
                        Websites
                      </a>
                    </li>

                    <li className="footer-menu-item">
                      <a href="#" className="footer-menu-link">
                        Branding
                      </a>
                    </li>

                    <li className="footer-menu-item">
                      <a href="#" className="footer-menu-link">
                        Ecommerce
                      </a>
                    </li>

                    <li className="footer-menu-item">
                      <a href="#" className="footer-menu-link">
                        SEO
                      </a>
                    </li>
                  </ul>

                  <strong className="site-footer-title d-block mt-4 mb-3">
                    Stay connected
                  </strong>

                  <ul className="social-icon">
                    <li className="social-icon-item">
                      <a
                        href="https://t.me/amanuelt312"
                        className="social-icon-link bi-telegram"
                      ></a>
                    </li>

                    <li className="social-icon-item">
                      <a
                        href="https://github.com/amanuelt312"
                        className="social-icon-link bi-github"
                      ></a>
                    </li>

                    <li className="social-icon-item">
                      <a
                        href="https://www.linkedin.com/in/amanuelt312/"
                        className="social-icon-link bi-linkedin"
                      ></a>
                    </li>

                    <li className="social-icon-item">
                      <a
                        href="mailto:amanuelt312@gmail.com"
                        className="social-icon-link bi-envelope"
                      ></a>
                    </li>
                  </ul>
                </div>
              </div>

              <div className="col-lg-3 col-md-6 col-12 ps-lg-0">
                <div className="contact-info d-flex flex-column">
                  <h5 className="mb-2">
                    Transforming your ideas into a digital reality
                  </h5>

                  <strong className="site-footer-title d-block mt-4 mb-3">
                    Email
                  </strong>

                  <p>
                    <a href="mailto:amanuelt312@gmail.com">
                      amanuelt312@gmail.com
                    </a>
                  </p>

                  <strong className="site-footer-title d-block mt-4 mb-3">
                    Call
                  </strong>

                  <p className="mb-0">
                    <a href="tel: 0963453433">0963453433</a>
                  </p>
                </div>
              </div>

              <div className="col-lg-6 col-12 mt-5 mt-lg-0">
                <form className="custom-form contact-form">
                  <div className="row">
                    <div className="col-lg-6 col-md-6 col-12">
                      <div className="form-floating">
                        <input
                          type="text"
                          name="name"
                          id="name"
                          className="form-control"
                          placeholder="Name"
                          required=""
                        />

                        {/* <label for="floatingInput">Name</label> */}
                        <label>Name</label>
                      </div>
                    </div>

                    <div className="col-lg-6 col-md-6 col-12">
                      <div className="form-floating">
                        <input
                          type="email"
                          name="email"
                          id="email"
                          pattern="[^ @]*@[^ @]*"
                          className="form-control"
                          placeholder="Email address"
                          required=""
                        />

                        {/* <label for="floatingInput">Email address</label> */}
                        <label>Email address</label>
                      </div>
                    </div>

                    <div className="col-lg-12 col-12">
                      <div className="form-floating">
                        <textarea
                          className="form-control"
                          id="message"
                          name="message"
                          placeholder="Tell me about the project"
                        ></textarea>

                        {/* <label for="floatingTextarea">
                          Tell me about the project
                        </label> */}
                        <label>Message</label>
                      </div>
                    </div>

                    <div className="col-lg-3 col-12 ms-auto">
                      <button
                        onClick={() => handleSubmit()}
                        className="form-control"
                      >
                        Send
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
          {/* </div> */}
        </section>
      </main>
    </>
  );
};
